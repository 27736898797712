<template>
  <form class="container" v-if="!loading">
    <div class="text" >
      <p>Confirmar Importação</p>
      <span>Confira os dados dos leads antes de concluir a importação. Certifique-se de que todos os detalhes estão corretos.</span>
    </div>
    <div class="container-content">
      <p>Visualização final dos leads</p>
      <div class="container-leads">
        <div v-for="(item, index) in removeFirst" :key="index" class="lead">
          <div class="icon">
            <svg width="12" height="10" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.35999 5.66547L3.03917 7.82442C3.09541 7.89738 3.16732 7.95678 3.2496 7.99823C3.33187 8.03968 3.4224 8.06212 3.51451 8.06389C3.60662 8.06566 3.69795 8.04672 3.78175 8.00847C3.86556 7.97021 3.9397 7.91362 3.9987 7.84287L9.3561 1.35986" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <ul>
              <li
                v-for="(item2, index) in sample[index + 1]"
                :key="index"
              >
                <div>
                  <span>{{ index == 'phone' ? 'Telefone' : index  == 'name' ? 'Nome' : index == 'email' ? 'E-mail' : index }}</span>
                  <p>{{ item2 }}</p>
                </div>
              </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container-buttons">
      <BaseButton  @click="$emit('step3')" variant="link-info">
        Voltar
      </BaseButton>
      <BaseButton
        @click="confirmarImportacao"
        variant="primary"
      >
        Confirmar importação
      </BaseButton>
    </div>
  </form>
  <div v-else class="d-flex justify-content-center" style="display: flex; align-items: center; justify-content: center; min-height: 500px" >
    <b-spinner label="Loading..."></b-spinner>
  </div>
</template>
<script>
import BaseButton from "@/components/BaseButton";

import ImportService from "@/services/resources/ImportService";
const serviceImport = ImportService.build();

export default {
  props: ['sample', 'selects', 'importData', 'importacao'],
  components: {
    BaseButton
  },
  data(){
    return{

    }
  },
  computed: {
    removeFirst() {
      console.log(this.sample)
      var result = [];
      this.sample.forEach((item) => {
        if (this.sample.indexOf(item) > 0) {
          result.push(item);
        }
      });
      return result;
    },
  },
  methods: {
    confirmarImportacao() {
      const headers = this.selects.map(item => item === "tags" ? "_tags" : item); 
      var data = {
        headers,
        status: "waiting",
      };
      if (!this.importacao) {
        data.id = `/${this.importData.id}`;
        serviceImport
          .createId(data)
          .then(() => {
            this.$grToast.toast("Importação realizada com sucesso", {
              title: "Importação de leads",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.$emit('submit')
          })
          .catch((err) => {
            this.$grToast.toast("Erro ao efetuar importação", {
              title: "Importação de leads",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          });
        return;
      }
      data.id = `/${this.importacao.id}`;
      serviceImport
        .createId(data)
        .then(() => {
          this.$grToast.toast("Importação realizada com sucesso", {
            title: "Importação de leads",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$emit('submit')
        })
        .catch((err) => {
          this.$grToast.toast("Erro ao efetuar importação", {
            title: "Importação de leads",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$emit('cancel')
        });
    },
  }
}
</script>
<style lang="scss" scoped>
.container{
  display: grid;
  gap: 35px;
  .text {
    display: grid;
    gap: 10px;
    p {
      font-weight: 600;
      font-size: 18px;
      color: var(--gray01);
    }
    span {
      font-weight: 400;
      font-size: 15px;
      color: #81858E;
    }
  }
  .container-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
  .container-content{
  display: grid;
  gap: 20px;
    p{
      font-weight: 600;
      font-size: 16px;
      color: var(--gray01);
    }
    .container-leads{
      display: flex;
      gap: 20px;
      .lead {
        position: relative;
        border: 1px solid #EDECF0;
        border-radius: 10px;
        width: 230px;
        padding: 22px;
        .icon {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          width: 25px;
          height: 25px;
          background-color: #00B72F;
          border-radius: 100%;
          right: 15px;
          top: 15px;
          path{
            stroke: white
          }
        }
        span {
          color: var(--base-medium);
          font-size: 12px;
          font-weight: 400;
        }
        p {
          color: var(--gray01);
          font-size: 14px;
          font-weight: 500;
        }
        ul {
          display: grid;
          gap: 22px;
          margin-bottom: 0;
          li:nth-child(n+2){
            border-top: 1px solid var(--white-light);
            margin-top: -10px;
            padding-top: 7px;
          }
        }
      }
    }
  }
}
</style>